<template>
	<div>
		<div style="overflow-y: auto; overflow-x: hidden;" :style="{height: height + 'px'}">
			<template v-for="(item, index) in items">
				<template v-if="item.children.length > 0">
					<div :key="index" style="margin-bottom: 30px">
						<div style="margin-bottom: 10px">
							<!--  一级资源标签 -->
							<v-checkbox
									:label=item.description
									style="font-weight: bold"
									hide-details
									:input-value="(item.option !== '1' && includesAllList(item.children)) || (item.option === '1' && includesOneElement(item.children))"
									:indeterminate="includesOneElement(item.children) && item.option !== '1'"
									@change="firstSourceChange($event, item, index)"/>
						</div>
						<template v-if="item.option === '1'">
							<!-- 二级资源标签：单选 -->
							<c-radio-group
									:items="item.children"
									:row="index"
									ref="radioRef"
									@change="secondRadioChange($event, item)"/>
						</template>
						<template v-else>
							<!-- 二级资源标签：多选 -->
							<v-row :key="'row_' + index" v-if="item.children">
								<template v-for="(subItem, i) in item.children">
									<v-col cols="6" :key="'sub_' + index + '_' + i">
										<v-checkbox
												:label=subItem.description
												hide-details
												style="margin-top: 4px; font-size: 14px"
												@change="secondCheckBoxChange($event, subItem)"
												:input-value="secondItems.includes(subItem.code)"
										/>
									</v-col>
								</template>
							</v-row>
						</template>
					</div>
				</template>
			</template>
		</div>
		<div>
			<v-checkbox
					label="全选"
					hide-details
					style="display: inline-block"
					:input-value="selectAll"
					@change="toggleSelectAll($event)"/>
			<div style="float: right; margin-top: 15px">
				<v-btn depressed outlined color="primary" @click="$emit('closeDialog')">取消</v-btn>
				<v-btn depressed color="primary" style="margin-left: 20px" @click="save">保存</v-btn>
			</div>
			<div style="clear: both;"></div>
		</div>
	</div>
</template>

<script>
import cRadioGroup from './c-radio-group.vue';

export default {
	components: {cRadioGroup},
	name: "cascadeCheckbox",
	props: {
		searchKey: {
			type: String,
			default: ''
		},
		form: {
			type: Object,
			default: () => ({})
		},
		api: {
			type: Object,
			default: () => ({})
		},
		height: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			items: [],
			// 选中的二级资源
			secondItems: [],
			// 是否全部选中
			selectAll: false
		}
	},
	methods: {
		// 全选、全不选事件
		toggleSelectAll(r) {
			if (r) {
				this.items.forEach((item, row) => {
					this.firstSourceChange(true, item, row);
				});
			} else {
				this.secondItems = [];
				if (this.$refs.radioRef) {
					for (const radioRef of this.$refs.radioRef) {
						radioRef.radioValue = '';
					}
				}
			}
		},
		// 一级资源改变事件
		firstSourceChange(r, item, row) {
			if (item.option === '1') {
				this.setRadioSelectOr(row, r ? item.children[0].code : '');
				// 加入或删除一级资源选择列表
				if (r) {
					// 默认加入第一个元素
					this.addSecondSourceItem(item.children[0])
				} else {
					item.children.forEach((sub) => {
						this.removeItemFromSecondItems(sub);
					});
				}
			} else {
				item.children.forEach((sub) => {
					this.secondCheckBoxChange(r, sub);
				});
			}
		},
		// 二级资源改变事件（多选框）
		secondCheckBoxChange(r, item) {
			if (r) {
				this.addSecondSourceItem(item);
			} else {
				this.removeItemFromSecondItems(item);
			}
		},
		// 二级资源改变事件（单选框）
		secondRadioChange(code, item) {
			item.children.forEach((i) => {
				// 删除存在表单的数据
				this.removeItemFromSecondItems(i);
				if (code === i.code) {
					this.addSecondSourceItem(i);
				}
			});
		},
		addSecondSourceItem(item) {
			let hasElement = false;
			this.secondItems.forEach((i) => {
				if (i === item.code) {
					hasElement = true;
				}
			});
			if (!hasElement) {
				this.secondItems.push(item.code);
			}
		},
		// 从二级资源集合删除元素
		removeItemFromSecondItems(item) {
			let secondItems = [];
			this.secondItems.forEach((i) => {
				if (i !== item.code) {
					secondItems.push(i);
				}
			});
			this.secondItems = secondItems;
		},
		// 数组包含另一个数组
		includesAllList(items) {
			let result = true;
			items.forEach((item) => {
				if (!this.secondItems.includes(item.code)) {
					result = false;
				}
			});
			return result;
		},
		// 数组包含数组中的一个元素（有且只有一个）
		includesOneElement(items) {
			let row = 0;
			items.forEach((item) => {
				if (this.secondItems.includes(item.code)) {
					row++;
				}
			});
			return row > 0 && row !== items.length;
		},
		// 设置radio选中
		setRadioSelectOr(row, value) {
			this.$nextTick(() => {
				let radioRefs = this.$refs.radioRef;
				for (const radioRef of radioRefs) {
					if (row === radioRef.row) {
						radioRef.radioValue = value;
					}
				}
			});
		},
		// 是否全选
		selectAllFunc() {
			let row = 0, all = 0, allRadio = true;
			this.items.forEach((item) => {
				if (item.option !== '1') {
					if (item.children && this.secondItems) {
						item.children.forEach((i) => {
							all++;
							if (this.secondItems.includes(i.code)) {
								row++;
							}
						});
					}
				} else if (!this.includesOneElement(item.children)) {
					allRadio = false;
				}
			});
			this.selectAll = (row !== 0 && row === all && allRadio);
		},
		save() {
			this.form.packagecode = this.secondItems.join(',');
			this.axios.post(this.api.save, this.form).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					this.snackbar.success('保存成功');
					this.$emit('closeDialog');
				}
			});
		}
	},
	mounted() {
		this.axios.post('/api/factor/header_list', {searchkey: this.searchKey}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.items = res.data;
				if (this.form.packagecode) {
					// 赋值
					this.secondItems = this.form.packagecode.split(",");
					// 此步骤检查资源是否被删除
					let temp = []
					this.items.forEach((item) => {
						if (item.children) {
							item.children.forEach(t => {
								temp.push(t.code)
							})
						}
					})
					let _secondItems = []
					this.secondItems.forEach(v => {
						if (temp.includes(v)) {
							_secondItems.push(v)
						}
					})
					this.secondItems = _secondItems
					this.items.forEach((item, row) => {
						if (item.option === '1' && item.children) {
							item.children.forEach((sub) => {
								if (this.secondItems.includes(sub.code)) {
									this.setRadioSelectOr(row, sub.code);
								}
							});
						}
					});
				}
			}
		});
	},
	watch: {
		secondItems: {
			handler() {
				this.selectAllFunc();
			}
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep {
	.mdi-minus-box::before {
		color: var(--v-primary-base);
	}

	.v-input--selection-controls__ripple {
		width: 0;
	}

	.v-label {
		color: #666666 !important;
	}
}

.col-6 {
	padding-top: 0;
	padding-bottom: 0;
}
</style>
