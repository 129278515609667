<template>
	<div>
		<exhibition-data-box
				:dialog.sync="dialog"
				title="库存&价格"
				:code="code"
				:contentHeight="fixedHeight + 50"
		>
			<common-table
					:key="new Date().getTime()"
					:headers="headers"
					:listData="listData"
					:loading="loading"
					:showPagination=false
					:dialogTable=true
					ref="ct"
					:fixedHeight="fixedHeight"
					:rightConfig="rightConfig"
					:normalPage="false"
					@otherRightOperate="otherRightOperate"
					@saveTableFiled="saveTableFiled"
			/>
		</exhibition-data-box>

		<template v-if="batchDialog">
			<form-menu
					:center="true"
					:center_btn="true"
					:listDeploy="listDeploy"
					height="320px"
					width="520px"
					:ridDivision="true"
					:dialog.sync="batchDialog"
					rightbtn_text="确定"
					leftbtn_text="取消"
					@resetForm="batchDialog = false"
					@validateSave="validateSave"
					ref="formRef"
					:title="batchTitle"/>
		</template>
	</div>
</template>

<script>
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/roomTypeStorePrice.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import storePrice from '../storePrice';

export default {
	components: {exhibitionDataBox, formMenu},
	mixins: [commonCurd, storePrice],
	props: {
		code: {
			type: String,
			default: () => ''
		},
		storePriceDialog: {
			type: Boolean,
			default: () => false
		}
	},
	data() {
		return {
			api,
			fixedHeight: 0,
			headers: [
				{text: '日期', value: 'date'},
				{text: '售卖价格', value: 'rate', editable: true},
				{text: '剩余库存', value: 'avl', editable: true},
				{text: '销量', value: 'pickup'}
			],
			dialog: false,
			rightConfig: [
				{label: '批量价格', icon: '', event: 'batchPrice'},
				{label: '批量库存', icon: '', event: 'batchStore'}
			],
			batchDialog: false,
			batchTitle: '',
			listDeploy: {}
		}
	},
	methods: {
		createdExtend() {
			this.form.searchkey = this.code;
			this.form.pages.pagesize = 999;
		},
		otherRightOperate(event) {
			this.batchDialog = true;
			if (event === this.rightConfig[0].event) {
				this.batchTitle = this.rightConfig[0].label;
				this.listDeploy = this.getDeploy('price', '售卖价格');
			} else if (event === this.rightConfig[1].event) {
				this.batchTitle = this.rightConfig[1].label;
				this.listDeploy = this.getDeploy('avl', '剩余库存');
			}
		},
		validateSave(item, callback) {
			if (item.done) {
				item.form.rmtype = this.code;
				this.axios.post(this.api.save, item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("保存成功");
						callback();
						this.list();
					} else {
						this.$refs.formRef.load = false;
					}
				});
			}
		}
	},
	watch: {
		storePriceDialog: {
			handler(v) {
				if (v) {
					this.dialog = true;
				}
			},
			immediate: true
		},
		dialog: {
			handler(v) {
				if (!v) {
					this.$emit("closeStorePriceDialog");
				}
			}
		}
	},
	mounted() {
		this.fixedHeight = document.documentElement.clientHeight * 0.6;
	}
}
</script>

<style scoped>

</style>
