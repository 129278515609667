<template>
	<v-radio-group
			v-model="radioValue"
			@change="radioChange"
			hide-details
	>
		<v-row v-if="items">
			<template v-for="(subItem, i) in items">
				<v-col cols="6" :key="'sub_' + row + '_' + i">
					<v-radio
							:label="subItem.description"
							:value="subItem.code"
					></v-radio>
				</v-col>
			</template>
		</v-row>
	</v-radio-group>
</template>

<script>
export default {
	name: "c-radio-group",
	props: {
		items: {
			type: Array,
			default: () => []
		},
		row: {
			type: Number,
			default: 0
		},
		firstChange: {
			type: Function,
			default: (r) => {
				console.log(r)
			}
		}
	},
	data() {
		return {
			radioValue: ''
		}
	},
	methods: {
		radioChange(r) {
			this.$emit('change', r);
		}
	}
}
</script>

<style scoped lang="scss">
.col-6 {
	padding-top: 0;
	padding-bottom: 0;
}
</style>
