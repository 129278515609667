<template>
	<div>
		<common-table
				:itemScope="['lsell', 'roomTypeSupporting', 'storePrice']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				pageTitle="房型"
				:listDeploy="listDeploy"
				@beforeOpenAddDialog="beforeOpenAddDialog"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:roomTypeSupporting="{ item }">
				<v-btn depressed color="primary" @click="roomTypeSupporting(item)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					选择配套
				</v-btn>
			</template>

			<template v-slot:storePrice="{ item }">
				<v-icon class="iconfont ml-2" color="primary" size="24" @click="storePrice(item.code)">iconfont icon-chakan-fill
				</v-icon>
			</template>
		</common-table>
		<template v-if="storePriceDialog">
			<store-price
					:code="typeCode"
					:storePriceDialog.sync="storePriceDialog"
					@closeStorePriceDialog="storePriceDialog=false"/>
		</template>

		<template v-if="kitDialog">
			<exhibition-data-box
					:dialog.sync="kitDialog"
					title="选择房型配套"
					:width="412"
					:contentHeight="650"
			>
				<cascade-checkbox
						:height="600"
						@closeDialog="kitDialog = false"
						searchKey="RMKIT"
						:form="roomTypeObject"
						:api="api"/>
			</exhibition-data-box>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/roomType.js';
import storePrice from "./roomTypeStorePrice.vue";
import exhibitionDataBox from '@/components/exhibition-data-box/exhibition-data-box.vue';
import cascadeCheckbox from "@/components/cascadeCheckbox/cascadeCheckbox.vue";

export default {
	mixins: [commonCurd],
	components: {storePrice, exhibitionDataBox, cascadeCheckbox},
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			typeCode: '',
			storePriceDialog: false,
			kitDialog: false,
			roomTypeObject: {},
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			leftConfig: [{type: 'input', label: '输入房型代码/名称', property: 'searchkey'}],
			headers: [
				{text: '房型代码', value: 'code'},
				{text: '房型名称', value: 'description'},
				{text: '配套设施', value: 'roomTypeSupporting'},
				{text: '排序号', value: 'seq'},
				{text: '状态', value: 'lsell'},
				{text: '库存&价格', value: 'storePrice'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "房型代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "房型名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'rulecode',
						connector: {
							props: {
								label: "业务规则",
								items: [],
								chips: true,
								required: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'showprice',
						connector: {
							props: {
								required: true,
								type: 'number',
								label: "市场价格"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'num',
						connector: {
							props: {
								label: "物理房间数",
								type: 'number',
								required: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'maxp',
						connector: {
							props: {
								label: "最大入住人数",
								type: 'number',
								required: true
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'bedenum',
						connector: {
							props: {
								label: "床型选择",
								items: [
									{desc: '大床', code: '1'},
									{desc: '双床', code: '2'},
									{desc: '单人床', code: '3'},
									{desc: '特大床', code: '4'},
									{desc: '榻榻米', code: '5'},
									{desc: '儿童床', code: '6'},
									{desc: '上下铺', code: '7'},
									{desc: '沙发床', code: '8'},
									{desc: '标间', code: '9'},
									{desc: '套房', code: '10'},
									{desc: '家庭房', code: '11'},
                  {desc: '床位', code: '12'}
								],
								chips: true
							}
						}
					},
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'mslidepics',
						connector: {
							props: {
								required: true,
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端轮播图'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'slidepics',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端轮播图(680*340)'
					// 		}
					// 	}
					// }
				]
			}
		}
	},
	methods: {
		// 配套设置
		roomTypeSupporting(item) {
			this.roomTypeObject = item;
			this.kitDialog = true;
		},
		// 库存&价格
		storePrice(item) {
			this.storePriceDialog = true;
			this.typeCode = item;
		},
		createdExtend() {
			this.fixedParams.hotelcode = this.$route.query.value;
		},
		beforeSave(form) {
			form.hotelcode = this.$route.query.value;
		},
		beforeOpenAddDialog() {
			this.getCommonSelect();
		},
		getCommonSelect() {
			this.axios.post(
					this.select_data, {keyname: "rules"}
			).then((res) => {
						if (res?.data) {
							this.listDeploy.row[2].connector.props.items = res.data[0].values;
						}
					}
			);
		}
	}
}
</script>

<style scoped lang="scss">

</style>
